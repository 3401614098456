import React from "react";
import style from "./Sets.module.css";

export default function Sets() {
  return (
    <>
      <h1>Sets</h1>
    </>
  );
}
