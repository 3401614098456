import React from "react";
import style from "./Coats.module.css";

export default function Coats() {
  return (
    <>
      <h1>Coats</h1>
    </>
  );
}
