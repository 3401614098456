import React from "react";
import style from "./Shop.module.css";

export default function Shop() {
  return (
    <>
      <h1>Shop</h1>
    </>
  );
}
